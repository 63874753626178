/**
 * Message store for the default implementation of OO.ui.msg.
 *
 * Environments that provide a localization system should not use this, but should override
 * OO.ui.msg altogether.
 *
 * @private
 */
OO.ui.msg.messages = {
	"ooui-copytextlayout-copy": "Copy",
	"ooui-outline-control-move-down": "Move item down",
	"ooui-outline-control-move-up": "Move item up",
	"ooui-outline-control-remove": "Remove item",
	"ooui-toolbar-more": "More",
	"ooui-toolgroup-expand": "More",
	"ooui-toolgroup-collapse": "Fewer",
	"ooui-item-remove": "Remove",
	"ooui-dialog-message-accept": "OK",
	"ooui-dialog-message-reject": "Cancel",
	"ooui-dialog-process-error": "Something went wrong",
	"ooui-dialog-process-dismiss": "Dismiss",
	"ooui-dialog-process-retry": "Try again",
	"ooui-dialog-process-continue": "Continue",
	"ooui-combobox-button-label": "Toggle options",
	"ooui-selectfile-button-select": "Select a file",
	"ooui-selectfile-button-select-multiple": "Select files",
	"ooui-selectfile-placeholder": "No file is selected",
	"ooui-selectfile-dragdrop-placeholder": "Drop file here",
	"ooui-selectfile-dragdrop-placeholder-multiple": "Drop files here",
	"ooui-popup-widget-close-button-aria-label": "Close",
	"ooui-field-help": "Help"
};
